import { environment } from '@env/environment';
import {AuthConfig} from '@fielddata.io/auth';

export const AUTH_CONFIG: AuthConfig = {
  clientID: environment.clientID,
  domain: environment.domain,
  responseType: 'token id_token',
  redirectUri: environment.serverUrl + 'callback',
  audience: 'https://portal.fielddata.io/usermgt',
  callbackURL: environment.serverUrl  + 'callback',
  homeUri: environment.serverUrl,
  auth0Tenant: environment.auth0Tenant,
  tokenIssuer: environment.tokenIssuer
};
