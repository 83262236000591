import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FooterComponent} from '@app/shared/footer/footer.component';
import {MaterialModule} from '@shared/material-module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchUsersPipe} from '@shared/pipes/search-users.pipe';
import {SearchEquipmentsPipe} from '@shared/pipes/search-equipments.pipe';
import {LimitToPipe} from '@shared/pipes/limit-to.pipe';
import {CookieService} from 'ngx-cookie-service';
import {RouterModule} from '@angular/router';
import {BreadcrumbModule} from '@fielddata.io/breadcrumb';
import {SpinnersModule} from '@fielddata.io/spinners';
import {HeaderModule, MenuAppsKeys, MenuOptions} from '@fielddata.io/header';
import {environment} from '@env/environment';
import {UtilsModule} from '@fielddata.io/utils';
import {AuthModule, AuthModuleOptions, InterceptorService} from '@fielddata.io/auth';
import {dynamicRoutes, userStaticMenu} from '@app/globals';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateCutPipe} from './pipes/translate-cut.pipe';
import {GoogleMapsModule} from '@angular/google-maps';
import {AUTH_CONFIG} from './services/auth/auth0-variables';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {BooleanStringPipe} from '@shared/pipes/boolean-string.pipe';
import {ShowOneTechniquePipe} from '@shared/pipes/show-one-technique.pipe';


@NgModule({
  declarations: [
    FooterComponent,
    SearchUsersPipe,
    SearchEquipmentsPipe,
    LimitToPipe,
    TranslateCutPipe,
    BooleanStringPipe,
    ShowOneTechniquePipe
  ],
  imports: [
    BreadcrumbModule,
    AuthModule.forRoot(
      environment,
      AUTH_CONFIG,
      {
        appKey: MenuAppsKeys.PORTAL,
        routesMap: dynamicRoutes,
      } as AuthModuleOptions
    ),
    ClipboardModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SpinnersModule,
    HeaderModule.forRoot(
      environment,
      {
        appKey: MenuAppsKeys.PORTAL,
        userMenu: userStaticMenu,
        routesMap: dynamicRoutes
      } as MenuOptions
    ),
    UtilsModule.forRoot(
      environment
    )
  ],
  exports: [
    BreadcrumbModule,
    AuthModule,
    ClipboardModule,
    FooterComponent,
    CommonModule,
    MaterialModule,
    HeaderModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LimitToPipe,
    TranslateCutPipe,
    SpinnersModule,
    UtilsModule,
    GoogleMapsModule,
    BooleanStringPipe,
    ShowOneTechniquePipe
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    SearchUsersPipe,
    SearchEquipmentsPipe,
    LimitToPipe,
    BooleanStringPipe,
    ShowOneTechniquePipe
  ]
})
export class SharedModule { }
