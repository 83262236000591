import { Pipe, PipeTransform } from '@angular/core';
import { UserOnProject } from '@models/User.model';

@Pipe({name: 'searchUsersPipe'})
export class SearchUsersPipe implements PipeTransform {
  public transform(users: UserOnProject[], searchString: string): UserOnProject[] {
    let filteredUsers = [];
    if (searchString === '') {
      filteredUsers = [...users];
    } else {
      users.forEach((user: UserOnProject) => {
        if (this.search(searchString, user)) {
          filteredUsers = [...filteredUsers, user];
        }
      });
    }
    return filteredUsers;
  }

  private search(stringValue: string, user: UserOnProject): boolean {
    return user.email?.toLowerCase().includes(stringValue.toLowerCase())
      || user.firstName?.toLowerCase().includes(stringValue.toLowerCase())
      || user.lastName?.toLowerCase().includes(stringValue.toLowerCase());
  }
}
