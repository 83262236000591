import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { BehaviorSubject, filter, switchMap } from 'rxjs';
import * as i1 from '@angular/router';
import { NavigationEnd, RouterModule } from '@angular/router';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
const _c0 = a0 => [a0];
function BreadcrumbComponent_div_0_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1, ">");
    i0.ɵɵelementEnd();
  }
}
function BreadcrumbComponent_div_0_ng_container_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "span", 4);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const crumb_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 1, crumb_r1.translateKey));
  }
}
function BreadcrumbComponent_div_0_ng_container_1_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "a", 5);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const crumb_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(5, _c0, crumb_r1.internalLink))("queryParams", crumb_r1.queryParams);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 3, crumb_r1.translateKey));
  }
}
function BreadcrumbComponent_div_0_ng_container_1_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "a", 6);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const crumb_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("href", crumb_r1.externalLink, i0.ɵɵsanitizeUrl)("target", crumb_r1.targetAttribute ? crumb_r1.targetAttribute : "_blank");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 3, crumb_r1.translateKey));
  }
}
function BreadcrumbComponent_div_0_ng_container_1_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "span", 4);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const crumb_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 1, crumb_r1.staticString));
  }
}
function BreadcrumbComponent_div_0_ng_container_1_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "a", 5);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const crumb_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(3, _c0, crumb_r1.internalLink))("queryParams", crumb_r1.queryParams);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(crumb_r1.staticString);
  }
}
function BreadcrumbComponent_div_0_ng_container_1_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "a", 6);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const crumb_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("href", crumb_r1.externalLink, i0.ɵɵsanitizeUrl)("target", crumb_r1.targetAttribute ? crumb_r1.targetAttribute : "_blank");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(crumb_r1.staticString);
  }
}
function BreadcrumbComponent_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, BreadcrumbComponent_div_0_ng_container_1_span_1_Template, 2, 0, "span", 2)(2, BreadcrumbComponent_div_0_ng_container_1_ng_container_2_Template, 4, 3, "ng-container", 0)(3, BreadcrumbComponent_div_0_ng_container_1_ng_container_3_Template, 4, 7, "ng-container", 0)(4, BreadcrumbComponent_div_0_ng_container_1_ng_container_4_Template, 4, 5, "ng-container", 0)(5, BreadcrumbComponent_div_0_ng_container_1_ng_container_5_Template, 4, 3, "ng-container", 0)(6, BreadcrumbComponent_div_0_ng_container_1_ng_container_6_Template, 3, 5, "ng-container", 0)(7, BreadcrumbComponent_div_0_ng_container_1_ng_container_7_Template, 3, 3, "ng-container", 0);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const crumb_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i_r2 > 0);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", crumb_r1.translateKey && !crumb_r1.internalLink && !crumb_r1.externalLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", crumb_r1.translateKey && crumb_r1.internalLink && !crumb_r1.externalLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", crumb_r1.translateKey && !crumb_r1.internalLink && crumb_r1.externalLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", crumb_r1.staticString && !crumb_r1.internalLink && !crumb_r1.externalLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", crumb_r1.staticString && crumb_r1.internalLink && !crumb_r1.externalLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", crumb_r1.staticString && !crumb_r1.internalLink && crumb_r1.externalLink);
  }
}
function BreadcrumbComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, BreadcrumbComponent_div_0_ng_container_1_Template, 8, 7, "ng-container", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.breadcrumb.crumbs);
  }
}
class BreadcrumbService {
  constructor(router, route) {
    this.router = router;
    this.route = route;
    this.breadcrumbSubject = new BehaviorSubject({
      crumbs: []
    });
    this.router.events.pipe(filter(event => event instanceof NavigationEnd), switchMap(() => this.getDeepestBreadCrumb().data), filter(data => data.breadcrumb !== undefined)).subscribe(data => this.setBreadcrumb(data.breadcrumb));
  }
  getBreadcrumbSubject() {
    return this.breadcrumbSubject;
  }
  setBreadcrumb(breadcrumb) {
    return this.breadcrumbSubject.next(breadcrumb);
  }
  // returns the deepest level to subscribe to
  getDeepestBreadCrumb() {
    let route = this.route;
    // find the deepest route level where firstChild is undefined or null
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
  static {
    this.ɵfac = function BreadcrumbService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BreadcrumbService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BreadcrumbService,
      factory: BreadcrumbService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Router
  }, {
    type: i1.ActivatedRoute
  }], null);
})();
class BreadcrumbComponent {
  constructor(breadcrumbService, cd) {
    this.breadcrumbService = breadcrumbService;
    this.cd = cd;
    this.breadcrumbSubscription = this.breadcrumbService.getBreadcrumbSubject().subscribe(data => {
      this.breadcrumb = data;
      this.cd.markForCheck();
    });
  }
  ngOnDestroy() {
    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }
  }
  static {
    this.ɵfac = function BreadcrumbComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BreadcrumbComponent)(i0.ɵɵdirectiveInject(BreadcrumbService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BreadcrumbComponent,
      selectors: [["fielddata-breadcrumb"]],
      decls: 1,
      vars: 1,
      consts: [[4, "ngIf"], [4, "ngFor", "ngForOf"], ["class", "separator", 4, "ngIf"], [1, "separator"], [1, "static-crumb"], [1, "dynamic-crumb", 3, "routerLink", "queryParams"], [1, "dynamic-crumb", 3, "href", "target"]],
      template: function BreadcrumbComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, BreadcrumbComponent_div_0_Template, 2, 1, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.breadcrumb && ctx.breadcrumb.crumbs);
        }
      },
      dependencies: [i2.NgForOf, i2.NgIf, i1.RouterLink, i4.TranslatePipe],
      styles: ["[_nghost-%COMP%]{background-color:#149ad7;color:#fff;padding-left:30px;min-height:30px;font-size:12px;font-weight:500;display:flex;align-items:center;line-height:1;width:100%;position:fixed;top:35px;z-index:3}[_nghost-%COMP%]   .separator[_ngcontent-%COMP%]{margin:0 20px}[_nghost-%COMP%]   .dynamic-crumb[_ngcontent-%COMP%]{color:#fff;text-decoration:none}[_nghost-%COMP%]   .dynamic-crumb[_ngcontent-%COMP%]:hover{text-decoration:underline}[_nghost-%COMP%]   .dynamic-crumb[_ngcontent-%COMP%]:visited{color:#fff}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbComponent, [{
    type: Component,
    args: [{
      selector: 'fielddata-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div *ngIf=\"breadcrumb && breadcrumb.crumbs\">\r\n  <ng-container *ngFor=\"let crumb of breadcrumb.crumbs; let i = index;\">\r\n    <span *ngIf=\"i > 0\" class=\"separator\">></span>\r\n\r\n    <ng-container *ngIf=\"crumb.translateKey && !crumb.internalLink && !crumb.externalLink\">\r\n      <span class=\"static-crumb\">{{ crumb.translateKey | translate }}</span>\r\n    </ng-container>\r\n\r\n    <ng-container *ngIf=\"crumb.translateKey && crumb.internalLink && !crumb.externalLink\">\r\n      <a [routerLink]=\"[crumb.internalLink]\" [queryParams]=\"crumb.queryParams\"\r\n         class=\"dynamic-crumb\">{{ crumb.translateKey | translate }}</a>\r\n    </ng-container>\r\n\r\n    <ng-container *ngIf=\"crumb.translateKey && !crumb.internalLink && crumb.externalLink\">\r\n      <a [href]=\"crumb.externalLink\" [target]=\"crumb.targetAttribute ? crumb.targetAttribute : '_blank'\"\r\n         class=\"dynamic-crumb\">{{ crumb.translateKey | translate }}</a>\r\n    </ng-container>\r\n\r\n    <ng-container *ngIf=\"crumb.staticString && !crumb.internalLink && !crumb.externalLink\">\r\n      <span class=\"static-crumb\">{{ crumb.staticString | translate }}</span>\r\n    </ng-container>\r\n\r\n    <ng-container *ngIf=\"crumb.staticString && crumb.internalLink && !crumb.externalLink\">\r\n      <a [routerLink]=\"[crumb.internalLink]\" [queryParams]=\"crumb.queryParams\" class=\"dynamic-crumb\">{{ crumb.staticString }}</a>\r\n    </ng-container>\r\n\r\n    <ng-container *ngIf=\"crumb.staticString && !crumb.internalLink && crumb.externalLink\">\r\n      <a [href]=\"crumb.externalLink\" [target]=\"crumb.targetAttribute ? crumb.targetAttribute : '_blank'\"\r\n         class=\"dynamic-crumb\">{{ crumb.staticString }}</a>\r\n    </ng-container>\r\n  </ng-container>\r\n</div>\r\n",
      styles: [":host{background-color:#149ad7;color:#fff;padding-left:30px;min-height:30px;font-size:12px;font-weight:500;display:flex;align-items:center;line-height:1;width:100%;position:fixed;top:35px;z-index:3}:host .separator{margin:0 20px}:host .dynamic-crumb{color:#fff;text-decoration:none}:host .dynamic-crumb:hover{text-decoration:underline}:host .dynamic-crumb:visited{color:#fff}\n"]
    }]
  }], () => [{
    type: BreadcrumbService
  }, {
    type: i0.ChangeDetectorRef
  }], null);
})();
class BreadcrumbModule {
  static {
    this.ɵfac = function BreadcrumbModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BreadcrumbModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BreadcrumbModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, RouterModule, TranslateModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbModule, [{
    type: NgModule,
    args: [{
      declarations: [BreadcrumbComponent],
      imports: [CommonModule, RouterModule, TranslateModule],
      exports: [BreadcrumbComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of breadcrumb
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BreadcrumbComponent, BreadcrumbModule, BreadcrumbService };
