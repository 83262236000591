{
  "name": "portal",
  "version": "1.18.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "analyze-portal": "ng build --configuration production --stats-json && cd dist/user-portal && esbuild-visualizer --metadata stats.json && stats.html",
    "start:prod": "ng serve --configuration production --aot",
    "build": "ng build --aot --output-hashing=all --base-href=/portal/",
    "test": "ng test",
    "test:ci": "ng test --no-watch --no-progress --browsers=ChromeHeadless",
    "lint": "node --max_old_space_size=12000 ./node_modules/@angular/cli/bin/ng lint && npm run lint:styles",
    "lint:styles": "stylelint \"{src,projects}/**/*.scss\"",
    "e2e": "ng e2e",
    "stats": "ng build --configuration production --aot --stats-json && npx webpack-bundle-analyzer dist/portal/stats.json",
    "deploy-header": "ng build header --configuration production && cd dist/header && npm publish",
    "deploy-auth": "cd projects/auth && npm install --legacy-peer-deps && cd ../.. && ng build auth --configuration production && cd dist/auth && npm publish",
    "deploy-breadcrumb": "ng build --configuration production breadcrumb && cd dist/breadcrumb && npm publish",
    "deploy-spinners": "ng build --configuration production spinners && cd dist/spinners && npm publish",
    "deploy-utils": "cd projects/utils && npm install --legacy-peer-deps && cd ../.. && ng build utils --configuration production && cd dist/utils && npm publish",
    "deploy-soil-profile": "cd projects/soil-profile && npm install --legacy-peer-deps && cd ../.. && ng build soil-profile --configuration production && cd dist/soil-profile && npm publish",
    "deploy-all": "npm run deploy-spinners && npm run deploy-header && npm run deploy-breadcrumb && npm run deploy-utils && npm run deploy-auth && npm run deploy-soil-profile",
    "link-header": "cd dist/header && sudo npm link && cd ../.. && npm link @fielddata.io/header && ng build header --watch",
    "unlink-header": "sudo npm unlink @fielddata.io/header && cd dist/header && sudo npm unlink && cd ../.. && npm install @fielddata.io/header --sapm run sve",
    "link-breadcrumb": "cd dist/breadcrumb && sudo npm link && cd ../.. && npm link @fielddata.io/breadcrumb && ng build breadcrumb --watch",
    "unlink-breadcrumb": "sudo npm unlink @fielddata.io/breadcrumb && cd dist/breadcrumb && sudo npm unlink && cd ../.. && npm install @fielddata.io/breadcrumb --save",
    "link-spinners": "cd dist/spinners && sudo npm link && cd ../.. && npm link @fielddata.io/spinners && ng build spinners --watch",
    "unlink-spinners": "sudo npm unlink @fielddata.io/spinners && cd dist/spinners && sudo npm unlink && cd ../.. && npm install @fielddata.io/spinners --save",
    "link-utils": "cd dist/utils && sudo npm link && cd ../.. && npm link @fielddata.io/utils && ng build utils --watch",
    "unlink-utils": "npm unlink @fielddata.io/utils && cd dist/utils && npm unlink && cd ../.. && npm install @fielddata.io/utils --save",
    "link-auth": "cd dist/auth && sudo npm link && cd ../.. && npm link @fielddata.io/auth && ng build auth --watch",
    "unlink-auth": "sudo npm unlink @fielddata.io/auth && cd dist/auth && sudo npm unlink && cd ../.. && npm install @fielddata.io/auth --save",
    "link-soil-profile": "cd dist/soil-profile && sudo npm link && cd ../.. && npm link @fielddata.io/soil-profile && ng build soil-profile --watch",
    "unlink-soil-profile": "sudo npm unlink @fielddata.io/soil-profile && cd dist/soil-profile && sudo npm unlink && cd ../.. && npm install @fielddata.io/soil-profile --save"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.0",
    "@angular/cdk": "^18.2.0",
    "@angular/common": "^18.2.0",
    "@angular/compiler": "^18.2.0",
    "@angular/core": "^18.2.0",
    "@angular/forms": "^18.2.0",
    "@angular/google-maps": "^18.2.0",
    "@angular/material": "^18.2.0",
    "@angular/material-moment-adapter": "^18.2.0",
    "@angular/platform-browser": "^18.2.0",
    "@angular/platform-browser-dynamic": "^18.2.0",
    "@angular/router": "^18.2.0",
    "@babel/runtime": "^7.20.13",
    "@fielddata.io/auth": "^2.4.2",
    "@fielddata.io/breadcrumb": "^1.17.2",
    "@fielddata.io/header": "^1.21.1",
    "@fielddata.io/performance-widget": "^1.12.2",
    "@fielddata.io/soil-profile": "^1.6.1",
    "@fielddata.io/spinners": "^1.15.2",
    "@fielddata.io/utils": "1.18.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@rxweb/reactive-form-validators": "^13.0.1",
    "core-js": "^3.20.2",
    "moment": "^2.29.4",
    "ngx-clipboard": "^16.0.0",
    "ngx-color-picker": "^17.0.0",
    "ngx-cookie-service": "^18.0.0",
    "ngx-device-detector": "^8.0.0",
    "ngx-image-cropper": "^8.0.0",
    "ngx-mask": "16.2.8",
    "ngx-mat-select-search": "^7.0.7",
    "normalize.css": "^8.0.1",
    "postcss": "^8.4.32",
    "roboto-fontface": "^0.10.0",
    "rxjs": "^7.5.1",
    "tar-fs": "^2.1.1",
    "tslib": "^2.3.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/architect": "^0.1802.0",
    "@angular-devkit/build-angular": "^18.2.1",
    "@angular-devkit/core": "^18.2.0",
    "@angular/cli": "^18.2.1",
    "@angular/compiler-cli": "^18.2.0",
    "@angular/language-service": "^18.2.0",
    "@eslint/js": "^9.9.0",
    "@types/jasmine": "^3.10.3",
    "@types/jasminewd2": "^2.0.10",
    "@types/node": "^16.11.18",
    "ajv": "^8.8.2",
    "angular-eslint": "^18.3.0",
    "angular-ide": "^0.9.77",
    "esbuild-visualizer": "^0.6.0",
    "eslint": "^9.9.0",
    "google-maps-mock": "github:dicbrus/google-maps-mock",
    "jasmine-core": "^4.0.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.3.16",
    "karma-chrome-launcher": "~3.1.0",
    "karma-cli": "^2.0.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.7.0",
    "ng-packagr": "^18.2.0",
    "protractor": "~7.0.0",
    "rxjs-compat": "^6.6.7",
    "stylelint": "^13.13.1",
    "stylelint-config-standard": "^22.0.0",
    "stylelint-scss": "^3.14.2",
    "ts-node": "^10.4.0",
    "typescript": "5.5.4",
    "typescript-eslint": "^8.2.0"
  }
}
