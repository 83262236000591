// @ts-ignore
import packageInfo from '../../package.json';

export const environment = {
  production: false,
  appVersion: `PORTAL v${packageInfo.version}-dev`,
  serverUrl: 'https://dev.fielddata.io/portal/',
  homeUrl: 'https://dev.fielddata.io/portal/',
  portalApiUrl: 'https://dev.fielddata.io/portalapi/',
  performanceApiUrl: 'https://dev.fielddata.io/performanceapi/',
  fileBoxUrl: 'https://dev.fielddata.io/filebox/login',
  qualityUrl: 'https://dev.fielddata.io/quality/',
  qualityApiUrl: 'https://dev.fielddata.io/qualityapi/',
  weatherServiceUrl: 'https://dev.fielddata.io/weatherapi/',
  clientID: 'hClrJ3hbia9dz3zdt63DqQWS7IXRJkyG',
  domain: 'id-dev.fielddata.io',
  logoutUrl: 'https://dev.fielddata.io/portal/',
  auth0Tenant: 'fielddata-dev.eu.auth0.com',
  tokenIssuer: 'id-dev.fielddata.io',
  webbgmUrl: 'https://testwebbgm.bauer.de'
};
