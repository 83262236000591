import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showOneTechnique'
})
export class ShowOneTechniquePipe implements PipeTransform {

  public transform(techniques: string[]): string {
    return techniques.length > 1 ? techniques[0] + " ... " : techniques[0];
  }

}
